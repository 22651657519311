// Superfish customised menu !!!!!!
// must be selected next options:
//   Style: none
//   Drop shadows: disabled
//  Максимальная ширина:  17em
// Минимальная ширина: 17em

ul.sf-menu.sf-horizontal.sf-style-none {
  float: left;
  margin-bottom: 1em;
  padding: 0;
  width: 100%;
  background: url("images/main_menu_bg.png") repeat-x scroll 0 0 rgba(0, 0, 0, 0);
  border-radius: 4px;

  a {
    color: #252525;
  }

  li {
    &.sf-depth-1 {
      background: url("images/afte_link.jpg") no-repeat scroll 100% 50% rgba(0, 0, 0, 0);
      padding-right: 2px;
      a.sf-depth-1 {
        display: block;
        font-size: 1.5em;
        height: 30px;
        line-height: 1.8em;
        padding: 0.55em 1.1em;
        text-decoration: none;
        &.active {
          background: url("images/hover_link.jpg") repeat-x scroll 0 0 rgba(0, 0, 0, 0);
          color: #1d5216;
        }
        &:hover {
          @extend a.sf-depth-1.active;
          border-radius: 4px;
          text-decoration: underline;
        }
        .sf-sub-indicator {
          background-position: 0 -100px;
          right: 0.4em;
          top: 0.9em;
        }
      }
      &:hover > ul, &.sfHover > ul {
        left: 0;
        top: 3.0em;
      }
      &.last {
        background: none;
      }

      // second level menu
      > ul {
        background: url("images/main_menu_sub_bg.png") no-repeat scroll 0 0 rgba(0, 0, 0, 0);
        border-bottom: 1px solid #c1b38e;
        border-radius: 0 0 4px 4px;
        margin-left: 0;
        margin-top: -10px;
        padding: 20px 5px 15px 5px;
        width: 248px;
        font-size: 1.17em;

        // third level menu
        ul {
          background: none repeat scroll 0 0 #fbf7ee;
          border: 1px solid #c1b38e;
          border-radius: 4px;
          left: 220px;
          margin-left: 0;
          margin-top: -10px;
          padding: 5px 5px 15px;
          top: 10px;
          width: 238px;
        }
      }
    }
    &.first a.active {
      border-radius: 4px 0 0 4px;
    }
  }

}

.sf-menu.sf-style-none.rtl {
  a.sf-with-ul {
    padding-left: 2.25em;
    padding-right: 1em;
  }
  ul.sf-megamenu li.sf-megamenu-wrapper ol li.sf-megamenu-column {
    float: right;
  }
}

// for hiding the menu while page load for mobile devices
#block-superfish-1 {
  display: none;
}
